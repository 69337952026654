// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconClipboardList,
  IconTrees,
  IconHeartHandshake,
  IconDashboard,
  IconUsers,
  IconAlignBoxLeftMiddle,
  IconBoxMultiple,
  IconWallet,
  IconPremiumRights,
  IconCreditCard,
  IconFilePencil,
  IconReport,
  IconUserPlus,
  IconFiles,
  IconBuildingStore,
  IconUpload,
  IconFileText,
} from "@tabler/icons";

// constant
const icons = {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconClipboardList,
  IconTrees,
  IconHeartHandshake,
  IconDashboard,
  IconUsers,
  IconAlignBoxLeftMiddle,
  IconBoxMultiple,
  IconWallet,
  IconPremiumRights,
  IconCreditCard,
  IconFilePencil,
  IconReport,
  IconUserPlus,
  IconFiles,
  IconBuildingStore,
  IconUpload,
  IconFileText,
};

// ==============================|| PROPONENT MENU ITEMS ||============================== //

const investor = {
  id: "investor",
  title: <FormattedMessage id="proponent" defaultMessage="Investor" />,
  icon: icons.IconApps,
  type: "group",
  roles: ["investor"],
  permissions: ["create:requests"],
  children: [
    {
      id: "investor_dashboard",
      title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
      type: "item",
      url: "/dashboard",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    {
      id: "investor_finance_applications",
      title: (
        <FormattedMessage
          id="Projects List"
          defaultMessage="Finance Applications"
        />
      ),
      type: "collapse",
      icon: icons.IconClipboardList,
      breadcrumbs: false,
      children: [
        {
          id: "investor_finance_applications_all",
          title: (
            <FormattedMessage id="all_applications" defaultMessage="All" />
          ),
          type: "item",
          url: "/applications",
          icon: icons.IconClipboardList,
          breadcrumbs: false,
        },
        {
          id: "investor_finance_applications_accessed",
          title: (
            <FormattedMessage
              id="accessed_projects"
              defaultMessage="Accessed"
            />
          ),
          type: "item",
          url: "/projects",
          icon: icons.IconClipboardList,
          breadcrumbs: false,
        },

        {
          id: "investor_finance_applications_requested",
          title: <FormattedMessage id="Requests" defaultMessage="Requests" />,
          type: "item",
          url: "/requests",
          icon: icons.IconClipboardList,
          breadcrumbs: false,
        },
      ],
    },
  ],
};

export default investor;
